import React from 'react';
// Helper function to convert measurement sizes to teaspoons for sorting
const convertToTeaspoons = (size) => {
    const unit = size.match(/[a-zA-Z]+$/)[0]; // Extract the unit
    const value = parseFloat(size); // Extract the numeric value

    const conversionRates = {
        'tsp': 1,
        'Tablespoon': 3,
        'cup': 48 // Assuming 1 cup = 48 teaspoons
    };

    return value * (conversionRates[unit] || 1); // Convert all measurements to teaspoons
};

const MeasurementLegend = () => {
    
    
    const measurements = [
        { size: '1/8 cup', color: '#ffffff' },
        { size: '1/8 tsp', color: '#ffffff' },
        { size: '1/4 cup', color: '#73a14a' },
        { size: '1/4 tsp', color: '#73a14a' },
        { size: '1/3 cup', color: '#ffff46' },
        { size: '1/2 cup', color: '#3a68a8' },
        { size: '1/2 tsp', color: '#3a68a8' },
        { size: '2/3 cup', color: '#e96a25' },
        { size: '1 cup', color: '#e6341d' },
        { size: '1 tsp', color: '#e6341d' },
        { size: '1 Tablespoon', color: '#6e386a' }
    ];
 /*     
        
        const measurements = [
        { size: '1/8 cup or tsp', color: '#ffffff' },
        { size: '1/4 cup or tsp', color: '#73a14a' },
        { size: '1/3 cup', color: '#ffff46' },
        { size: '1/2 cup or tsp', color: '#3a68a8' },
        { size: '2/3 cup', color: '#e96a25' },
        { size: '1 cup or tsp', color: '#e6341d' },
        { size: '1 Tablespoon', color: '#6e386a' }
    ]; */
    // Sort measurements by converting each size to a common unit (teaspoons)
    measurements.sort((a, b) => {
        return convertToTeaspoons(a.size) - convertToTeaspoons(b.size);
    });

    return (
        <div style={{ borderRadius:'10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginBottom:'10px' }}>
            <h3 style={{ width: '100%', textAlign: 'center', marginBottom: '10px' }}>MEASUREMENT</h3>
            {measurements.map((measurement, index) => (
                <div key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '20px',
                    marginBottom: '10px'
                }}>
                    <div className="measurementLegend" style={{
                        backgroundColor: measurement.color,
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        marginRight: '10px',
                        border: '1px solid #333'
                    }}></div>
                    <span style={{ color: '#333', fontSize: '14px' }}>{measurement.size}</span>
                </div>
            ))}
        </div>
    );
};

export default MeasurementLegend;
