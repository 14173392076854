import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import MeasurementLegend from './MeasurementLegend.js';
import Footer from './Footer.js';
import { requestWakeLock, releaseWakeLock } from './wakeLock.js';

const measurementColors = {
  '⅛ tsp': '#ffffff',
  '1/8 tsp': '#ffffff',
  '⅛ teaspoon': '#ffffff',
  '¼ tsp': '#73a14a',
  '1/4 tsp': '#73a14a',
  '¼ teaspoon': '#73a14a',
  '½ tsp': '#3a68a8',
  '1/2 tsp': '#3a68a8',
  '½ teaspoons': '#3a68a8',
  '½ teaspoon': '#3a68a8',
  '1 tsp': '#e6341d',
  '1 tablespoon': '#6e386a',
  '1 Tablespoon': '#6e386a',
  '1 Tbsp': '#6e386a',
  '1 T': '#6e386a',
  '⅛ cup': '#ffffff',
  '1/8 cup': '#ffffff',
  '¼ cup': '#73a14a',
  '¼ cup,': '#73a14a',
  '1/4 cup': '#73a14a',
  '1/4 cup,': '#73a14a',
  '¼ cups': '#73a14a',
  '1/4 cups': '#73a14a',
  '1/4 cups,': '#73a14a',
  '⅓ cup': '#ffff46',
  '1/3 cup': '#ffff46',
  '½ cup': '#3a68a8',
  '½ cup, ': '#3a68a8',
  '½ cups': '#3a68a8',
  '½ cups,': '#3a68a8',
  '1/2 cup': '#3a68a8',
  '1/2 cups': '#3a68a8',
  '1/2 cups,': '#3a68a8',
  '⅔ cup': '#e96a25',
  '1 cup': '#e6341d',
  '3 oz': '#e96a25',
};

const isDarkColor = (color) => {
  const hexColor = color.replace('#', '');
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
};

const API_URL = process.env.REACT_APP_API_URL;
//const API_URL = 'https://seabird.local:3003';


function RecipeDetail() {
  const [recipe, setRecipe] = useState(null);
  const [ingredients, setIngredients] = useState({});
  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [activeStep, setActiveStep] = useState(null);
  const [textSize, setTextSize] = useState(16);
  const [checkedIngredients, setCheckedIngredients] = useState({});
  const { id } = useParams();
  const [isLegendVisible, setIsLegendVisible] = useState(false);

  useEffect(() => {
    requestWakeLock();
    return () => releaseWakeLock();
  }, []);

  useEffect(() => {
    fetch(`${API_URL}/api/recipe_name_description/${id}`)
      .then((response) => response.json())
      .then((data) => setRecipe(data.data))
      .catch(() => setError('Failed to fetch recipe details'));
  }, [id]);

  useEffect(() => {
    fetch(`${API_URL}/api/directions/${id}`)
      .then((response) => response.json())
      .then((data) => setDirections(data.data))
      .catch(() => setError('Failed to fetch directions'));
  }, [id]);

  useEffect(() => {
    fetch(`${API_URL}/api/ingredients/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const sectionedIngredients = data.data.reduce((acc, ingredient) => {
          acc[ingredient.section] = acc[ingredient.section] || [];
          acc[ingredient.section].push(ingredient);
          return acc;
        }, {});
        setIngredients(sectionedIngredients);
        const initialExpandedState = {};
        Object.keys(sectionedIngredients).forEach((section) => {
          initialExpandedState[section] = Object.keys(sectionedIngredients).length === 1;
        });
        setExpandedSections(initialExpandedState);

        const initialCheckedState = {};
        data.data.forEach((ingredient) => {
          initialCheckedState[ingredient.id] = false;
        });
        setCheckedIngredients(initialCheckedState);
      })
      .catch(() => setError('Failed to fetch ingredients'));
  }, [id]);

  const handleStepClick = (stepNumber) => setActiveStep(stepNumber);

  const handleButtonClick = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleLegendVisibility = () => setIsLegendVisible(!isLegendVisible);

  const increaseTextSize = () => setTextSize((size) => size + 2);
  const decreaseTextSize = () => setTextSize((size) => size - 2);

  const handleCheckboxChange = (ingredientId) => {
    setCheckedIngredients((prevState) => ({
      ...prevState,
      [ingredientId]: !prevState[ingredientId],
    }));
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!recipe || !directions || !ingredients) {
    return <div>Loading...</div>;
  }

  const highlightMeasurements = (quantity) => {
    const parts = quantity
      .split(/(\s+\+\s+|\s+or\s+|\s*,\s*|\sto\s|\s*(?=\())/)
      .filter(Boolean);
    return parts.map((part, index) => {
      const trimmedPart = part.trim();
      const color = measurementColors[trimmedPart] || 'inherit';
      const textColor = isDarkColor(color) ? '#ffffff' : '#000000';
      const hasBorder = color !== 'inherit';
      return (
        <span
          key={index}
          style={{
            backgroundColor: color,
            color: textColor,
            padding: '4px 4px',
            borderRadius: '4px',
            marginBottom: '20px',
            border: hasBorder ? '1px solid #000000' : 'none',
          }}
        >
          {trimmedPart}
        </span>
      );
    });
  };

  return (
    <>
      <div className="controls">
        <Link className="goBackButton" to="/">
          ⇤ Back to Recipes
        </Link>
        <span className="topButtons">
          <button onClick={increaseTextSize}>↑ Larger Font</button>
          <button onClick={decreaseTextSize}>↓ Smaller Font</button>
        </span>
      </div>

      <div className="container">
        <button className="ML_button" onClick={toggleLegendVisibility}>
          {isLegendVisible ? 'Hide Measure Guide' : 'Show Measure Guide'}
        </button>
        {isLegendVisible && (
          <div className="fixed-legend">
            <MeasurementLegend />
          </div>
        )}
        <div className="header">
          <h1 style={{ fontSize: `20px` }}>{recipe.recipe_name}</h1>
        </div>

        <div className="content">
          <div className="ingredients-section">
            {Object.entries(ingredients).map(([section, items], index) => (
              <div
                className={`section ${
                  expandedSections[section] ? 'expanded' : 'collapsed'
                }`}
                key={index}
              >
                <div className="section-header">
                  <h4>{section}</h4>
                  <button
                    className="button"
                    onClick={() => handleButtonClick(section)}
                  >
                    {expandedSections[section]
                      ? 'Tap to collapse'
                      : 'Tap to expand'}
                  </button>
                </div>
                {expandedSections[section] && (
                  <table>
                    <thead>
                      <tr>
                        <th style={{ fontSize: `${textSize}px` }}></th>
                        <th style={{ fontSize: `${textSize}px` }}>Ingredient</th>
                        <th style={{ fontSize: `${textSize}px` }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((ingredient, idx) => (
                        <tr
                          key={ingredient.id}
                          style={{
                            backgroundColor:
                              ingredient.step_number === activeStep
                                ? '#def'
                                : 'transparent',
                          }}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={checkedIngredients[ingredient.id]}
                              onChange={() => handleCheckboxChange(ingredient.id)}
                            />
                          </td>
                          <td
                            data-step={ingredient.step_number}
                            style={{
                              fontSize: `${textSize}px`,
                              backgroundColor:
                                activeStep === ingredient.step_number ? '#def' : 'transparent',
                              textDecoration: checkedIngredients[ingredient.id]
                                ? 'line-through'
                                : 'none',
                            }}
                          >
                            {ingredient.ingredient}
                          </td>
                          <td
                            data-step={ingredient.step_number}
                            style={{
                              fontSize: `${(textSize - 3)}px`,
                              backgroundColor:
                                activeStep === ingredient.step_number ? '#def' : 'transparent',
                              textDecoration: checkedIngredients[ingredient.id]
                                ? 'line-through'
                                : 'none',
                                whiteSpace:'nowrap'
                            }}
                          >
                            {highlightMeasurements(ingredient.quantity)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ))}
          </div>
          <div className="directions-section" style={{ fontSize: `${textSize}px` }}>
            <h3>Directions:</h3>
            <ol>
              {directions.map((direction, index) => (
                <li
                  key={index}
                  onClick={() => handleStepClick(direction.step_number)}
                  style={{
                    width: '90%',
                    borderRadius: '8px',
                    padding: '10px',
                    cursor: 'pointer',
                    backgroundColor:
                      activeStep === direction.step_number ? '#def' : 'transparent',
                  }}
                >
                  {direction.direction_text}
                </li>
              ))}
            </ol>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default RecipeDetail;

