import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';
import reportWebVitals from './reportWebVitals.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

serviceWorkerRegistration.register({
	onUpdate: registration => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', event => {
				if (event.target.state === 'activated') {
					window.location.reload();
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
	onSuccess: () => {
		console.log('Service worker registered successfully');
	},
});

reportWebVitals();
