import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './SplashScreen.js';
import RecipeList from './RecipeList.js';
import RecipeDetail from './RecipeDetail.js';
import './App.css';
import './index.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const hasLoadedBefore = localStorage.getItem('hasLoadedBefore');
    if (hasLoadedBefore) {
      setIsLoading(false);
    }

    // Set timeout to reset the flag after 10 seconds
    const timer = setTimeout(() => {
      localStorage.removeItem('hasLoadedBefore');
    }, 10000);

    // Add event listener to reset the flag when the app is closed
    const handleBeforeUnload = () => {
      localStorage.removeItem('hasLoadedBefore');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Simulate content loading
    setTimeout(() => {
      setContentLoaded(true);
    }, 1000); // Simulating loading time

    // Cleanup the timer and event listener
    return () => {
      clearTimeout(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);

          navigator.serviceWorker.addEventListener('message', event => {
            if (event.data && event.data.type === 'CACHE_UPDATE') {
              if (event.data.status === 'STARTED') {
                // Show update notification
                showUpdateNotification('App is updating. Please wait...');
              } else if (event.data.status === 'COMPLETED') {
                // Hide update notification
                hideUpdateNotification();
                alert('App update completed! You may need to refresh the page.');
              }
            }
          });
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    }

    function showUpdateNotification(message) {
      const notification = document.getElementById('update-notification');
      notification.textContent = message;
      notification.style.display = 'block';
    }

    function hideUpdateNotification() {
      const notification = document.getElementById('update-notification');
      notification.style.display = 'none';
    }
  }, []);

  if (isLoading) {
    return <SplashScreen setIsLoading={setIsLoading} />;
  }

  return (
    <Router>
      {contentLoaded ? (
        <>
          <Routes>
            <Route path="/" element={<RecipeList />} />
            <Route path="/recipes" element={<RecipeList />} />
            <Route path="/recipes/:id" element={<RecipeDetail />} />
          </Routes>
          <div id="update-notification" style={{ display: 'none', position: 'fixed', bottom: 0, left: 0, right: 0, background: '#ffc', textAlign: 'center', padding: '1em', borderTop: '1px solid #ccc' }}>
            {/* Update messages will be displayed here */}
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Router>
  );
}

export default App;

