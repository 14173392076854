// src/components/fetchWithCache.js
export async function fetchWithCache(url) {
  const CACHE_NAME = 'workbox-runtime';
  const cache = await caches.open(CACHE_NAME);
  const cachedResponse = await cache.match(url);

  if (cachedResponse) {
    console.log(`Serving from cache: ${url}`);
    return cachedResponse.json();
  }

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok for ${url}`);
    }
    await cache.put(url, response.clone());
    return response.json();
  } catch (error) {
    console.error(`Network request failed for ${url}, checking cache:`, error);
    if (cachedResponse) {
      return cachedResponse.json();
    }
    throw error; // If network and cache both fail, throw an error
  }
}

