import React from 'react';

const Footer = () => {
    return (
        <footer style={{ textAlign: 'center', padding: '20px', marginTop: '20px', fontSize:'10px', fontWeight:'bold' }}>
            <p style={{ margin: 0 }}>MADE WITH ❤️ BY DAILEY</p>
        </footer>
    );
};

export default Footer;
