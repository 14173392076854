import React, { useEffect, useState } from 'react';
import seaBirdImage from './images/sea-bird.png'; // Adjust the path if necessary
import './SplashScreen.css'; // Make sure this path is correct


const SplashScreen = ({ setIsLoading }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem('hasLoadedBefore', 'true');
      }, 1000); // Duration of the fade-out effect
    }, 1000); // Display the splash screen for 1 second before fading out

    return () => clearTimeout(timer);
  }, [setIsLoading]);

  return (
    <div className={`splash-screen ${fadeOut ? 'fade-out' : ''}`}>
      <img style={{borderRadius:'100px'}} src={seaBirdImage} alt="Sea Bird" />
      <h1>The Seabird Cookbook</h1>
      <p style={{ margin: 0, fontSize: '11px' }}>MADE WITH ❤️ BY DAILEY</p>
    </div>
  );
};



export default SplashScreen;
