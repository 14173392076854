// wakeLock.js
let wakeLock = null;

export const requestWakeLock = async () => {
    try {
        wakeLock = await navigator.wakeLock.request('screen');
        console.log('Wake lock is active.');
        
        wakeLock.addEventListener('release', () => {
            console.log('Wake lock was released.');
        });
    } catch (err) {
        console.error(`Failed to obtain wake lock: ${err}`);
    }
};

export const releaseWakeLock = async () => {
    if (wakeLock !== null) {
        await wakeLock.release();
        wakeLock = null;
        console.log('Wake lock is released.');
    }
};